import React from "react";
import classNames from "classnames";

interface Props {
  value: string;
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
}

export const Input: React.FC<Props> = ({
  value,
  placeholder,
  className,
  onChange,
  onEnter,
}) => {
  return (
    <input
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onEnter) {
          onEnter();
        }
      }}
      className={classNames(
        className,
        "w-full h-8 bg-white border-brand-blue border-[1px] px-2 rounded-lg"
      )}
    />
  );
};
