import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yLight as theme } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faTimes,
  faChevronLeft,
  faCheck,
  faCloudDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button";
import classNames from "classnames";
import { Action } from "./chat/Action";

interface Props {
  input: string;
  codes: any;
  outputs: any;
  query_optimal?: boolean;
  query_fixes?: any;
  task_id: string;
  onExit: any;
  historical?: boolean;
  loading?: boolean;
  plots?: any[];
  loadingTime?: number;
  onRedo?: (ids: string[], newQuery?: string) => void;
  files?: any;
}

export const ResultOverlay: React.FC<Props> = ({
  input,
  codes,
  outputs,
  query_optimal,
  query_fixes,
  onExit,
  task_id,
  loading,
  historical,
  loadingTime,
  plots,
  onRedo,
  files,
}) => {
  const [currentOutput, setCurrentOutput] = React.useState(0);
  const [currentCode, setCurrentCode] = React.useState(0);

  const devMode = !localStorage.getItem("devMode") ? false : true;

  const changeOutputView = (increment: number) => {
    if (increment === 1 && currentOutput < outputs.length - 1) {
      setCurrentOutput((currentOutput) => currentOutput + 1);
      setCurrentCode((currentCode) => currentCode + 1);
    } else if (increment === -1 && currentOutput > 0) {
      setCurrentOutput((currentOutput) => currentOutput - 1);
      setCurrentCode((currentCode) => currentCode - 1);
    }
  };

  const upvote = () => {
    fetch("https://api.eule.ai/upvote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id: task_id,
      }),
    });
  };

  const downvote = () => {
    fetch("https://api.eule.ai/downvote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id: task_id,
      }),
    });
  };

  const downloadCurrentCode = () => {
    if (document) {
      const element = document.createElement("a");
      const file = new Blob([codes[currentCode]], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = input + ".py";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  return (
    <div className="relative">
      <div className="left-0 right-0 m-auto mt-20 text-center max-w-[70rem]">
        "{input}"
      </div>
      <div className="flex left-0 right-0 gap-4 m-auto mt-4 w-[70rem] h-[30rem] max-h-[50rem] rounded-xl overflow-hidden">
        {/* CODE */}
        <div
          className="relative w-[34rem] h-[100%] rounded-xl overflow-hidden"
          id="code"
        >
          <SyntaxHighlighter
            language="python"
            style={theme}
            className="h-[100%] w-full"
            showLineNumbers={true}
          >
            {codes[currentCode] || ""}
          </SyntaxHighlighter>
          <Action
            onClick={downloadCurrentCode}
            className="absolute top-2 right-2 hover:brightness-110 duration-200"
          >
            <FontAwesomeIcon
              icon={faCloudDownload}
              color="white"
              className="w-6 h-6"
            />
          </Action>
          <div className="absolute bottom-1 right-2 text-sm font-bold text-brand-gray">
            code
          </div>
        </div>
        {/* OUTPUTS */}
        <div
          className="relative w-[50%] h-[100%] flex flex-col gap-2 items-center justify-center rounded-xl overflow-hidden"
          id="output"
        >
          {plots && plots?.length !== 0 && (
            <div
              className={classNames(
                {
                  // "h-full": outputs[currentOutput] === "",
                  // "h-[50%]": outputs[currentOutput] !== "",
                },
                "relative w-full flex items-center justify-center h-full rounded-xl bg-white"
              )}
            >
              <img
                src={`https://api.eule.ai/image/${plots[currentCode]}`}
                alt="Plot"
                className="max-h-full max-w-full"
              />
              <Action
                onClick={() => {
                  window.open(
                    `https://api.eule.ai/download_image/${plots[currentCode]}`,
                    "_blank"
                  );
                }}
                className="absolute top-2 right-2 hover:brightness-110 duration-200"
              >
                <FontAwesomeIcon
                  icon={faCloudDownload}
                  color="white"
                  className="w-6 h-6"
                />
              </Action>
              <div className="absolute bottom-1 right-2 text-sm font-bold text-brand-gray">
                plot
              </div>
            </div>
          )}

          {((outputs[currentOutput] !== "" && !plots) ||
            (plots && plots.length === 0)) && (
            <SyntaxHighlighter
              language="text"
              style={theme}
              className="h-full w-full"
              showLineNumbers={true}
              wrapLongLines={true}
            >
              {outputs[currentOutput] || ""}
            </SyntaxHighlighter>
          )}

          {((outputs[currentOutput] !== "" && !plots) ||
            (plots && plots.length === 0)) && (
            <div className="absolute bottom-1 right-2 text-sm font-bold text-brand-gray">
              console
            </div>
          )}
        </div>
      </div>

      <div className="flex gap-2 items-center top-2 right-2 text-white w-full mt-4 rounded-lg">
        <Action onClick={onExit}>
          <FontAwesomeIcon icon={faChevronLeft} />
          Exit
        </Action>
        {/* <Button
          text="Again"
          color="blue"
          onClick={() => {
            if (onRedo) {
              onRedo(files);
            }
          }}
        /> */}

        {/* RANKING */}

        {/* <div
          className={classNames("flex w-full justify-end gap-2", {
            "mr-8": codes.length > 1,
          })}
        >
          <div
            className="flex flex-col gap-4 w-10 h-10 justify-center items-center rounded-md cursor-pointer duration-200 bg-brand-blue hover:brightness-110"
            onClick={upvote}
          >
            <img
              src="/images/like.png"
              className="w-6 h-6 invert"
              alt="upvote"
            />
          </div>
          <div
            className="flex flex-col gap-4 w-10 h-10 justify-center items-center rounded-md cursor-pointer duration-200 bg-brand-blue hover:brightness-110"
            onClick={downvote}
          >
            <img
              src="/images/dislike.png"
              className="w-6 h-6 invert"
              alt="upvote"
            />
          </div>
        </div> */}

        {/* RANKING */}

        {/* {codes.length !== 1 && (
          <div
            className={classNames(
              "flex justify-end items-center gap-4 text-white bottom-4 pointer-events-auto",
              {
                "w-full": !devMode,
              }
            )}
          >
            <div className="flex gap-2 bg-brand-blue p-2 rounded-xl">
              <div
                className="flex justify-center items-center rounded-full cursor-pointer hover:opacity-90 duration-200"
                onClick={() => changeOutputView(-1)}
              >
                <FontAwesomeIcon icon={faAngleLeft} className="h-6 w-6" />
              </div>
              <div className="text-xl w-8 flex justify-center items-center pointer-events-none">
                {currentCode + 1}
              </div>
              <div
                className="flex justify-center items-center rounded-full cursor-pointer hover:opacity-90 duration-200"
                onClick={() => changeOutputView(1)}
              >
                <FontAwesomeIcon icon={faAngleRight} className="h-6 w-6" />
              </div>
            </div>
          </div>
        )} */}
      </div>

      {/* {query_fixes.length > 0 && (
        <div className="absolute flex flex-col w-full items-center justify-center text-center mt-4">
          <div className="text-2xl">Did you mean?</div>
          <div className="flex flex-wrap gap-2 mt-2 justify-center">
            {query_fixes.map((fixed_query: string) => (
              <div
                onClick={() => {
                  onRedo?.(
                    files.map((file: any) => file.id),
                    fixed_query
                  );
                }}
                className="flex gap-2 border-brand-blue border-[1px] p-2 rounded-xl cursor-pointer hover:bg-brand-blue hover:bg-opacity-20 duration-200"
              >
                {fixed_query}
              </div>
            ))}
          </div>
        </div>
      )} */}
    </div>
  );
};
