import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "../components/Button";
import { FileCard } from "../components/FileCard";
import { Logo } from "../components/Logo";
import { faPlus, faWarning } from "@fortawesome/free-solid-svg-icons";
import useForceUpdate from "../hooks/useForceUpdate";
import { AddFiles } from "../components/modals/AddFiles";
import { HistoryBlock } from "../components/HistoryBlock";
import { nanoid } from "nanoid";
import { Input } from "../components/Input";
import { ResultOverlay } from "../components/ResultOverlay";
import { GeneratingScreen } from "../components/GeneratingScreen";
import { CSSTransition } from "react-transition-group";
import { TalkHistoryButtons } from "../components/TalkHistoryButtons";
import { MultiLineInput } from "../components/MultiLineInput";
import { EditFile } from "../components/modals/EditFile";
import { useNavigate } from "react-router-dom";
import useGlobalState from "../states/globalStates";
import { ErrorPage } from "../components/ErrorPage";
import { UserChat } from "../components/UserChat";

export const Dashboard = () => {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  if (window.innerWidth < 768) {
    navigate("/");
  }

  const [selectedTab, setSelectedTab] = React.useState(2);
  const [showAddFiles, setShowAddFiles] = React.useState(false);
  let [query, setQuery] = React.useState("");
  const [historySearchQuery, setHistorySearchQuery] = React.useState("");
  const history = localStorage.getItem("history");
  let parsedHistory = JSON.parse(history || "[]");
  const [showableHistory, setShowableHistory] = React.useState(parsedHistory);

  if (localStorage.getItem("token") === null) {
    localStorage.setItem("token", nanoid());
  }

  const token = localStorage.getItem("token");

  const [files, setFiles] = useGlobalState("files");

  const [hideDashboard, setHideDashboard] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showErrorPage, setShowErrorPage] = React.useState(false);
  const [errorPageError, setErrorPageError] = React.useState("");

  const [codes, setCodes] = React.useState<any[]>([]);
  const [outputs, setOutputs] = React.useState<any[]>([]);
  const [queryOptimal, setQueryOptimal] = React.useState(false);
  const [queryFixes, setQueryFixes] = React.useState([]);
  const [plots, setPlots] = React.useState<any[]>([]);

  const [currentTaskId, setCurrentTaskId] = React.useState("");

  const [devMode, setDevMode] = React.useState(
    !localStorage.getItem("devMode") ? false : true
  );

  const [maintenance_mode, setMaintenanceMode] = useGlobalState("maintenance");
  const [maintenanceAccess] = useGlobalState("maintenanceAccess");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    fetch("https://api.eule.ai/maintenance_mode", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.maintenance_mode && !maintenanceAccess) {
          setMaintenanceMode(true);
          navigate("/");
        }
      });
  }, []);

  const start = async (q?: string) => {
    if (q) query = q;
    console.log(query);
    if (query === "[DEVMODE]") {
      if (devMode) localStorage.removeItem("devMode");
      if (!devMode) localStorage.setItem("devMode", "true");
      setDevMode(!devMode);
      setQuery("");
      return;
    }

    const selectedFiles = files
      .filter((file: any) => file.selected)
      .map((file: any) => file.id);

    if (!query) {
      setError("Please enter a query");
      return;
    }
    if (selectedFiles.length === 0) {
      setError("Please select at least one file");
      return;
    }

    setShowResult(false);
    setHideDashboard(true);
    let isError = false;

    if (!isError) {
      const controller = new AbortController();

      setTimeout(() => controller.abort(), 99999999999);

      const request = await fetch("https://api.eule.ai/inference", {
        method: "POST",
        body: JSON.stringify({
          token: token,
          ids: selectedFiles,
          query: query,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        signal: controller.signal,
      });

      if (request.status === 500) {
        setShowErrorPage(true);
        setErrorPageError("Internal Server Error");
        return;
      } else if (request.status !== 200) {
        setShowErrorPage(true);
        setErrorPageError("Unknown Error Occured");
        return;
      }

      const data = await request.json();

      if (data.error !== "") {
        console.log(data);
        setShowErrorPage(true);
        setErrorPageError(data.error);
        return;
      }

      const taskId = data.task_id;

      setCurrentTaskId(taskId);
      setShowLoading(true);
    }
  };

  const redo = async (ids: string[]) => {
    files.forEach((file: any) => {
      file.selected = false;
      if (ids.includes(file.id)) {
        file.selected = true;
      }
    });

    start();
  };

  React.useEffect(() => {
    const selectedFiles = files
      .filter((file: any) => file.selected)
      .map((file: any) => file.id);

    fetch("https://api.eule.ai/user?token=" + token, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem("files", JSON.stringify(res.files));
        localStorage.setItem("history", JSON.stringify(res.history));
        setFiles(
          res.files.map((file: any) => ({
            ...file,
            selected: selectedFiles.includes(file.id),
          }))
        );
        setShowableHistory(res.history);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddFiles, selectedTab]);

  const [editingFile, setEditingFile] = React.useState(undefined);
  const [showEditFile, setShowEditFile] = React.useState(false);

  const showDeep = (
    title: string,
    codes: any[],
    outputs: any[],
    plots: any[]
  ) => {
    setQuery(title);
    setCodes(codes);
    setOutputs(outputs);
    setPlots(plots);
    setShowResult(true);
    setHideDashboard(true);
  };

  return (
    <div className="flex flex-col pt-10 items-center">
      {!hideDashboard && showAddFiles && (
        <AddFiles
          onExit={() => {
            setShowAddFiles(false);
          }}
        />
      )}

      {!hideDashboard && (
        <div className="relative flex flex-col w-[60rem] max-w-[60rem]">
          <div className="relative w-full max-w-full h-12 overflow-hidden flex justify-center">
            <CSSTransition
              in={selectedTab === 0}
              timeout={300}
              classNames="talk"
              unmountOnExit
            >
              <div className="text-4xl font-bold text-brand-blue">
                Talk to your data
              </div>
            </CSSTransition>

            <CSSTransition
              in={selectedTab === 1}
              timeout={300}
              classNames="history"
              unmountOnExit
            >
              <div className="text-4xl font-bold text-brand-blue">
                Explore your history
              </div>
            </CSSTransition>
          </div>

          {selectedTab === 0 && !hideDashboard && (
            <div className="mt-4">
              {error && (
                <div className="flex items-center gap-2 text-red-500 font-light">
                  <FontAwesomeIcon icon={faWarning} color="red" />
                  {error && error}
                </div>
              )}
              <div className="flex w-[40rem]">
                <MultiLineInput
                  value={query}
                  onChange={setQuery}
                  placeholder="Enter your query"
                  className="h-20 shadow-md text-xl rounded-r-none"
                  onEnter={start}
                />
                <Button
                  text="Go"
                  className="font-bold text-xl h-20 w-32 rounded-l-none"
                  color="blue"
                  onClick={start}
                />
              </div>
            </div>
          )}

          {selectedTab === 1 && (
            <div className="flex mt-4 w-[40rem]">
              <Input
                value={historySearchQuery}
                onChange={(value: string) => {
                  if (value === "") {
                    setHistorySearchQuery("");
                    parsedHistory = JSON.parse(history || "[]");
                    setShowableHistory(parsedHistory);
                    return;
                  }

                  setHistorySearchQuery(value);
                  parsedHistory = JSON.parse(history || "[]").filter(
                    (item: any) => {
                      return item.query
                        .toLowerCase()
                        .includes(value.toLowerCase());
                    }
                  );

                  setShowableHistory(parsedHistory);
                }}
                placeholder="Search your history"
                className="h-16 shadow-md text-xl"
              />
            </div>
          )}

          <div
            className={
              selectedTab === 2
                ? ""
                : "relative mt-4 w-[40rem] h-[30rem] bg-white rounded-xl max-w-full overflow-y-scroll overflow-x-hidden shadow-xl"
            }
          >
            <CSSTransition
              in={selectedTab === 0}
              timeout={300}
              classNames="talk"
              unmountOnExit
            >
              {/* {!showHistory && ( */}
              <div className="grid grid-cols-4 w-[40rem] h-[30rem] p-4 gap-4 auto-rows-min">
                {devMode && (
                  <div
                    className="flex gap-1 items-center justify-center bg-white border-[1px] border-brand-blue aspect-square rounded-xl text-brand-gray font-bold text-lg hover:opacity-80 cursor-pointer duration-200"
                    onClick={() => {
                      setShowAddFiles(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Add
                  </div>
                )}
                {files.map((file: any) => (
                  <FileCard
                    fileName={file.name}
                    fileTitle={file.title}
                    fileDescription={file.description}
                    selected={file.selected}
                    onEdit={() => {
                      setEditingFile(file);
                      setShowEditFile(true);
                    }}
                    onClick={() => {
                      // files.forEach((f: any) => {
                      //   if (f !== file) {
                      //     f.selected = false;
                      //   }
                      // });
                      const selectedCount = files.filter(
                        (file: any) => file.selected
                      ).length;
                      if (selectedCount === 2 && !file.selected) {
                        for (let i = 0; i < files.length; i++) {
                          const file = files[i];
                          if (file.selected) {
                            file.selected = false;
                            break;
                          }
                        }
                      }
                      files[files.indexOf(file)].selected = !file.selected;
                      forceUpdate();
                    }}
                    onDelete={() => {
                      files.splice(files.indexOf(file), 1);
                      fetch(
                        "https://api.eule.ai/delete?token=" +
                          token +
                          "&id=" +
                          file.id,
                        { method: "POST" }
                      );
                      localStorage.setItem("files", JSON.stringify(files));
                      forceUpdate();
                    }}
                  />
                ))}
              </div>
              {/* )} */}
            </CSSTransition>

            <CSSTransition
              in={selectedTab === 1}
              timeout={300}
              classNames="history"
              unmountOnExit
            >
              {/* {showHistory && ( */}
              <div className="grid grid-cols-2 w-[40rem] h-[30rem] p-4 gap-4 auto-rows-min">
                {showableHistory.map(
                  (item: {
                    query: string;
                    description: string;
                    id: string;
                    result: { result: string };
                    pinned: boolean | undefined;
                    imgs: [];
                    file_ids: string[];
                  }) => {
                    if (item.pinned) {
                      return (
                        <HistoryBlock
                          title={item.query}
                          description={item.description}
                          index={parsedHistory.indexOf(item)}
                          pinned={item?.pinned}
                          onPin={() => {
                            if (!item.pinned) {
                              fetch(
                                "https://api.eule.ai/pin_history?token=" +
                                  token +
                                  "&id=" +
                                  item.id,
                                { method: "POST" }
                              );
                              item.pinned = true;
                            } else {
                              fetch(
                                "https://api.eule.ai/unpin_history?token=" +
                                  token +
                                  "&id=" +
                                  item.id,
                                { method: "POST" }
                              );
                              item.pinned = false;
                            }
                            forceUpdate();
                          }}
                          onEdit={() => {
                            files.forEach((file: any) => {
                              file.selected = false;
                            });
                            setQuery(item.query);
                            setSelectedTab(0);
                          }}
                          onRemove={() => {
                            parsedHistory = parsedHistory.filter(
                              (historyItem: any) => historyItem.id !== item.id
                            );

                            localStorage.setItem(
                              "history",
                              JSON.stringify(parsedHistory)
                            );
                            setShowableHistory(parsedHistory);
                            forceUpdate();
                            fetch(
                              "https://api.eule.ai/delete_history?token=" +
                                token +
                                "&id=" +
                                item.id,
                              { method: "POST" }
                            );
                          }}
                          onShow={() => {
                            const tmp_result = JSON.parse(item.result.result);
                            const tmp_codes = tmp_result.map(
                              (item: any) => item[0]
                            );
                            const tmp_outputs = tmp_result.map(
                              (item: any) => item[1]
                            );

                            files.forEach((file: any) => {
                              if (item.file_ids.includes(file.id)) {
                                file.selected = true;
                              }
                            });
                            setQueryOptimal(true);
                            setQueryFixes([]);
                            setCurrentTaskId(item.id);
                            setQuery(item.query);
                            setCodes(tmp_codes);
                            setOutputs(tmp_outputs);
                            setHideDashboard(true);
                            setShowResult(true);
                            setPlots(item.imgs);
                          }}
                        />
                      );
                    }
                  }
                )}

                {showableHistory.map(
                  (item: {
                    query: string;
                    description: string;
                    id: string;
                    result: { result: string };
                    pinned: boolean | undefined;
                    imgs: [];
                    file_ids: string[];
                  }) => {
                    if (!item.pinned) {
                      return (
                        <HistoryBlock
                          title={item.query}
                          description={item.description}
                          index={parsedHistory.indexOf(item)}
                          pinned={item?.pinned}
                          onPin={() => {
                            if (!item.pinned) {
                              fetch(
                                "https://api.eule.ai/pin_history?token=" +
                                  token +
                                  "&id=" +
                                  item.id,
                                { method: "POST" }
                              );
                              item.pinned = true;
                            } else {
                              fetch(
                                "https://api.eule.ai/unpin_history?token=" +
                                  token +
                                  "&id=" +
                                  item.id,
                                { method: "POST" }
                              );
                              item.pinned = false;
                            }
                            forceUpdate();
                          }}
                          onEdit={() => {
                            files.forEach((file: any) => {
                              file.selected = false;
                            });
                            setQuery(item.query);
                            setSelectedTab(0);
                          }}
                          onRemove={() => {
                            parsedHistory = parsedHistory.filter(
                              (historyItem: any) => historyItem.id !== item.id
                            );

                            localStorage.setItem(
                              "history",
                              JSON.stringify(parsedHistory)
                            );
                            setShowableHistory(parsedHistory);
                            forceUpdate();
                            fetch(
                              "https://api.eule.ai/delete_history?token=" +
                                token +
                                "&id=" +
                                item.id,
                              { method: "POST" }
                            );
                          }}
                          onShow={() => {
                            const tmp_result = JSON.parse(item.result.result);
                            const tmp_codes = tmp_result.map(
                              (item: any) => item[0]
                            );
                            const tmp_outputs = tmp_result.map(
                              (item: any) => item[1]
                            );

                            files.forEach((file: any) => {
                              if (item.file_ids.includes(file.id)) {
                                file.selected = true;
                              }
                            });
                            setQueryOptimal(true);
                            setQueryFixes([]);
                            setCurrentTaskId(item.id);
                            setQuery(item.query);
                            setCodes(tmp_codes);
                            setOutputs(tmp_outputs);
                            setHideDashboard(true);
                            setShowResult(true);
                            setPlots(item.imgs);
                          }}
                        />
                      );
                    }
                  }
                )}

                {parsedHistory.length === 0 && (
                  <div className="absolute text-gray-500 flex w-full h-full items-center justify-center">
                    You have no history yet. Run some queries to see them here
                  </div>
                )}
              </div>
              {/* )} */}
            </CSSTransition>

            <CSSTransition
              in={selectedTab === 2}
              timeout={300}
              classNames="talk"
              unmountOnExit
            >
              <UserChat showDeep={showDeep} />
            </CSSTransition>
          </div>

          {/* <TalkHistoryButtons
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          /> */}
        </div>
      )}

      {hideDashboard && !showLoading && !showResult && !showErrorPage && (
        <div className="flex flex-col items-center justify-center w-full h-full font-bold text-4xl text-brand-blue">
          Processing...
        </div>
      )}

      {showLoading && (
        <GeneratingScreen
          taskId={currentTaskId}
          onAbort={() => {
            setShowLoading(false);
            setHideDashboard(false);
          }}
          onFinish={(data: any) => {
            console.log(data);
            setShowLoading(false);
            const result = JSON.parse(data.result);

            console.log(data);

            if (data.codes_errors.every((a: boolean) => a)) {
              setErrorPageError("No valid code found");
              setShowLoading(false);
              setShowErrorPage(true);
              setQueryFixes(data.query_fixes);
              setQueryOptimal(data.query_optimal);
              return;
            }

            const codes_tmp: any[] = [];
            const outputs_tmp: any[] = [];

            result.forEach((value: any) => {
              codes_tmp.push(value[0]);
              outputs_tmp.push(value[1]);
            });

            setCodes(codes_tmp);
            setOutputs(outputs_tmp);
            setQueryOptimal(data.query_optimal);
            setQueryFixes(data.query_fixes);
            setPlots(data.imgs);

            setShowResult(true);
          }}
          onError={(err: string) => {
            setShowLoading(false);
            setShowErrorPage(true);
            setErrorPageError(err);
          }}
        />
      )}

      {showErrorPage && hideDashboard && !showResult && (
        <ErrorPage
          error={errorPageError}
          query_fixes={queryFixes}
          files={files
            .filter((file: any) => file.selected === true)
            .map((file: any) => file.id)}
          onExit={() => {
            setHideDashboard(false);
            setSelectedTab(0);
            setShowErrorPage(false);
          }}
          onRedo={(ids: string[], newQuery?: string) => {
            if (newQuery) {
              setQuery(newQuery);
            }
            redo(ids);
          }}
        />
      )}

      {showEditFile && (
        <div id="edit">
          <EditFile file={editingFile} onExit={() => setShowEditFile(false)} />
        </div>
      )}

      {showResult && hideDashboard && (
        <div id="result">
          <ResultOverlay
            input={query}
            codes={codes}
            outputs={outputs}
            task_id={currentTaskId}
            query_optimal={queryOptimal}
            query_fixes={queryFixes}
            loading={showLoading}
            // file={files.find((file: any) => file.selected === true)}
            plots={plots}
            files={files
              .filter((file: any) => file.selected === true)
              .map((file: any) => file.id)}
            onExit={() => {
              setShowResult(false);
              setHideDashboard(false);
            }}
            onRedo={(ids: string[], newQuery?: string) => {
              if (newQuery) {
                setQuery(newQuery);
              }
              redo(ids);
            }}
          />
        </div>
      )}

      <div className="mt-8">
        This is an early stage version of Eule, anything might be subject to
        change
      </div>
    </div>
  );
};
