/* eslint no-use-before-define: 0 */ // --> OFF

import React from "react";
import useForceUpdate from "../hooks/useForceUpdate";
import "../App.css";
import "../styles/animations.css";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";
import { Logo } from "../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import useGlobalState from "../states/globalStates";
import { Input } from "../components/Input";

export default function App() {
  const [maintenance_mode, setMaintenanceMode] = useGlobalState("maintenance");
  const [maintenanceKey, setMaintenanceKey] = useGlobalState("maintenanceKey");
  const [validMaintenanceKey, setValidMaintenanceKey] = React.useState(false);
  const [maintenanceAccess, setMaintenanceAccess] =
    useGlobalState("maintenanceAccess");
  const navigate = useNavigate();

  localStorage.setItem("token", "demotoken");

  const isMobile = window.innerWidth < 768;

  React.useEffect(() => {
    fetch("https://api.eule.ai/maintenance_mode", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.maintenance_mode) {
          setMaintenanceMode(true);
        } else {
          setMaintenanceMode(false);
        }
      });
  }, []);

  const maintenanceLogin = () => {
    fetch("https://api.eule.ai/login_maintenance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        password: maintenanceKey,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMaintenanceAccess(true);
          navigate("/dashboard");
        }
      });
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Logo />

      {!isMobile && (
        <div className="text-4xl 2xl:text-6xl pb-8 leading-loose gradient-text text-center">
          Empowering insights, automating discovery.
        </div>
      )}

      {isMobile && (
        <div className="text-2xl px-4 pb-8 leading-loose text-black text-center">
          Sorry, eule is not yet available on mobile.
        </div>
      )}

      <Button
        text="Try now for free"
        color="gradient"
        hoverAnimation={isMobile ? "none" : "scaleUp"}
        className="text-xl rounded-xl p-4 pl-8 pr-8"
        onClick={() => {
          navigate("/dashboard");
        }}
        endIcon={<FontAwesomeIcon icon={faChevronRight} />}
        disabled={isMobile || maintenance_mode}
      />

      <img
        src="/wave.svg"
        width="100%"
        className="absolute bottom-0 w-full h-[30vh]"
        alt="wave"
      />

      {maintenance_mode && (
        <div className="fixed top-0 left-0 w-full h-full">
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 backdrop-blur-xl" />
          <div className="fixed top-0 left-0 w-full h-full flex flex-col gap-4 items-center justify-center px-[20%]">
            <div className="text-4xl text-white text-center">
              We are currently in maintenance mode.
            </div>
            <div className="flex gap-2 w-[30rem]">
              <Input
                value={maintenanceKey}
                onChange={setMaintenanceKey}
                placeholder="Enter your magic password"
                className="h-10 w-full text-center"
                onEnter={maintenanceLogin}
              />
              <Button text="Continue" color="blue" onClick={maintenanceLogin} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
