import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useDropzone } from "react-dropzone";

interface Props {
  onFile: (fs: any) => void;
}

export const Dropzone: React.FC<Props> = ({ onFile }) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({});
  const [fileName, setFileName] = React.useState("");
  React.useEffect(() => {
    onFile(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className="relative flex flex-col items-center justify-center grow border-brand-1 cursor-pointer overflow-hidden h-full"
    >
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faFileArrowUp} className="h-24" color="#7087FF" />
      <div className="text-2xl font-bold text-brand-purple text-brand-1 mt-4">
        {isDragActive && <p>Release your files</p>}
        {!isDragActive && <p>Upload your files</p>}
      </div>

      {isDragActive && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-10"></div>
      )}
    </div>
  );
};
