import { createGlobalState } from "react-hooks-global-state";

const initialMessages: {
  role: string;
  code: string;
  content: string;
  id: string;
  image?: boolean;
  aborted?: boolean;
}[] = [];

const { useGlobalState } = createGlobalState({
  maintenance: false,
  maintenanceAccess: false,
  maintenanceKey: "",
  files: JSON.parse(localStorage.getItem("files") || "[]"),
  chatMessages: initialMessages,
  chatMessage: "",
});

export default useGlobalState;
