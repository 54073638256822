import React from "react";
import { Button } from "../Button";
import { FileConfig } from "../FileConfig";

import ReactLoading from "react-loading";

interface Props {
  file: any;
  onExit: () => void;
}

export const EditFile: React.FC<Props> = ({ file, onExit }) => {
  return (
    <div>
      <div
        className="fixed top-0 left-0 w-full h-full z-10 bg-black opacity-50"
        onClick={onExit}
      />
      <div className="flex flex-col gap-2 fade-in fixed top-0 left-0 right-0 bottom-0 m-auto w-96 max-h-96 h-fit bg-brand-white rounded-xl z-[11] p-2 overflow-y-scroll overflow-x-hidden break-words">
        {/* <div className="text-red-500 text-center">{errorMessage}</div> */}
        <FileConfig file={file} />
        <Button
          text="Save"
          color="blue"
          onClick={async () => {
            if (file.title && file.description) {
              await fetch("https://api.eule.ai/edit_file", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  token: localStorage.getItem("token"),
                  id: file.id,
                  title: file.title,
                  description: file.description,
                }),
              });
              onExit();
            }
          }}
        />
      </div>
    </div>
  );
};
