import { faPen, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { ResultOverlay } from "./ResultOverlay";

interface Props {
  title: string;
  description: string;
  index: number;
  pinned?: boolean;
  onPin: () => void;
  onRemove: () => void;
  onShow: () => void;
  onEdit: () => void;
}

export const HistoryBlock: React.FC<Props> = ({
  title,
  description,
  index,
  pinned,
  onPin,
  onRemove,
  onShow,
  onEdit,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      className="relative flex flex-col grow m-auto w-full h-40 p-2 select-none break-words bg-white border-[1px] border-brand-blue rounded-xl border-b-2 overflow-y-hidden cursor-pointer border-brand-1 text-ellipsis"
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full" onClick={onShow} />
      <div
        className="w-[90%] h-[3rem] overflow-hidden text-ellipsis line-clamp-2  z-[2] font-bold text-brand-gray"
        onClick={onShow}
      >
        {title}
      </div>

      <div
        onClick={onPin}
        className={classNames(
          "absolute top-2 right-2 flex items-center justify-center duration-200",
          {
            "opacity-0": !pinned,
            "opacity-100": !pinned && hovered,
          }
        )}
      >
        <FontAwesomeIcon icon={faStar} color={pinned ? "gold" : "gray"} />
      </div>

      <div
        className={classNames(
          "absolute bottom-2 duration-200 right-2 text-white"
        )}
      >
        <div className="flex gap-1">
          <div
            onClick={onEdit}
            className="flex items-center justify-center w-6 h-6 bg-brand-blue rounded-md duration-200 hover:opacity-90"
          >
            <FontAwesomeIcon icon={faPen} className="duration-200 w-4 h-4" />
          </div>
          <div
            onClick={onRemove}
            className="flex items-center justify-center w-6 h-6 bg-brand-blue rounded-md duration-200 hover:opacity-90"
          >
            <FontAwesomeIcon icon={faTrash} className="duration-200 w-4 h-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
