import classNames from "classnames";
import React from "react";

interface Props {
  text: string;
  color?: string;
  hoverAnimation?: string;
  className?: string;
  startIcon?: any;
  endIcon?: any;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<Props> = ({
  text,
  color,
  hoverAnimation = "default",
  className,
  startIcon,
  endIcon,
  disabled,
  onClick,
}) => {
  return (
    <div
      className={classNames(
        className,
        "flex items-center justify-center gap-2 p-2 pl-4 pr-4 cursor-pointer rounded-lg duration-200 select-none",
        {
          "bg-brand-blue text-white": color === "blue",
          "bg-brand-light-blue": color === "light-blue",
          "bg-brand-pink": color === "pink",
          "bg-brand-gray": color === "gray",
          "gradient-background text-white": color === "gradient",

          "hover:opacity-90 hover:shadow-lg": hoverAnimation === "default",
          "hover:rotate-6": hoverAnimation === "rotate",
          "hover:scale-110": hoverAnimation === "scaleUp",

          "opacity-50 cursor-not-allowed": disabled,
        }
      )}
      onClick={() => {
        if (!disabled) {
          onClick && onClick();
        }
      }}
    >
      {startIcon}
      {text}
      {endIcon}
    </div>
  );
};
