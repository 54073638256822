import React from "react";
import { Button } from "../Button";
import { Dropzone } from "../Dropzone";
import { FileConfig } from "../FileConfig";

import ReactLoading from "react-loading";

interface Props {
  onExit: () => void;
}

export const AddFiles: React.FC<Props> = ({ onExit }) => {
  const [files, setFiles] = React.useState<any>([]);
  const [fileCount, setFileCount] = React.useState(0);
  let [filesLeftToRead, setFilesLeftToRead] = React.useState(0);

  const [errorMessage, setErrorMessage] = React.useState("");

  const [uploadStage, setUploadStage] = React.useState(0); // 0 = file upload, 1 = file config, 2 = uploading screen, 3 = finish

  const readFiles = () => {
    const file = files[0];

    var data = new FormData();
    data.append("file", file, file.name);
    data.append("query", "get the column with most data");

    const fileReader = new FileReader();

    const handleFileRead = async (e: ProgressEvent<FileReader>) => {
      if (fileReader.result?.toString()) {
        await fetch(
          "https://api.eule.ai/upload?token=" + localStorage.getItem("token"),
          {
            method: "POST",
            headers: { "Content-Type": "text" },
            body: JSON.stringify({
              name: file.name,
              title: file.title,
              description: file.description,
              data: fileReader.result?.toString(),
            }),
          }
        );
      }

      filesLeftToRead -= 1;

      if (filesLeftToRead === 0) {
        setUploadStage(3);
      }
    };

    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
    files.shift();

    if (files.length > 0) {
      readFiles();
    }
  };

  const fileChangeHandler = (fs: any) => {
    // handle validations
    if (fs.length > 0) {
      setFiles(fs);
      setFilesLeftToRead(fs.length);
      setFileCount(fs.length);
      setUploadStage(1);
    }
  };

  return (
    <div>
      <div
        className="fixed top-0 left-0 w-full h-full z-10 bg-black opacity-50"
        onClick={onExit}
      />
      {uploadStage === 0 && (
        <div className="fade-in fixed top-0 left-0 right-0 bottom-0 m-auto w-96 h-96 bg-brand-white rounded-xl z-[11]">
          <Dropzone onFile={fileChangeHandler} />
        </div>
      )}
      {uploadStage === 1 && (
        <div className="flex flex-col gap-2 fade-in fixed top-0 left-0 right-0 bottom-0 m-auto w-96 max-h-96 h-fit bg-brand-white rounded-xl z-[11] p-2 overflow-y-scroll overflow-x-hidden break-words">
          <div className="text-red-500 text-center">{errorMessage}</div>
          {files.map((file: any) => (
            <FileConfig file={file} />
          ))}
          <Button
            text="Save"
            color="blue"
            onClick={() => {
              let foundIncomplete = false;

              files.forEach((file: any) => {
                if (!file.title || !file.description) {
                  foundIncomplete = true;
                  return;
                }
              });

              if (foundIncomplete) {
                setErrorMessage("Please fill out all fields");
                return;
              }
              setErrorMessage("");
              readFiles();
              setUploadStage(2);
            }}
          />
        </div>
      )}

      {uploadStage === 2 && (
        <div className="flex flex-col items-center justify-center gap-2 fade-in fixed top-0 left-0 right-0 bottom-0 m-auto w-96 max-h-96 h-fit bg-brand-white rounded-xl z-[11] p-2 overflow-y-scroll overflow-x-hidden break-words">
          <div className="text-2xl text-brand-blue font-bold">
            Uploading {fileCount} file{fileCount !== 1 ? "s" : ""}
          </div>
          <ReactLoading type="cubes" color="#4E7AEB" />
        </div>
      )}

      {uploadStage === 3 && (
        <div className="flex flex-col items-center justify-center gap-2 fade-in fixed top-0 left-0 right-0 bottom-0 m-auto w-96 h-96 bg-brand-white rounded-xl z-[11] p-2 overflow-y-scroll overflow-x-hidden break-words">
          <div className="text-2xl text-brand-blue font-bold">
            File{fileCount === 1 ? "" : "s"} uploaded successfully
          </div>
        </div>
      )}
    </div>
  );
};
