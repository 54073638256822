import React from "react";
import { Input } from "./Input";
import ReactLoading from "react-loading";
import useGlobalState from "../states/globalStates";
import classNames from "classnames";
import { Button } from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroom,
  faBrush,
  faChevronDown,
  faMicroscope,
  faPaperPlane,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { Action } from "./chat/Action";
import { Logo } from "./Logo";
import { Suggestion } from "./chat/Suggestion";
import {
  createTheme,
  CssBaseline,
  Switch,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

export const UserChat: React.FC<{
  showDeep: (title: string, codes: any[], outputs: any[], plots: any[]) => void;
}> = ({ showDeep }) => {
  const theme = createTheme({
    shape: {
      borderRadius: 5000,
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });
  const token = localStorage.getItem("token");
  const inDevMode = !!localStorage.getItem("devMode");
  const [files, setFiles] = useGlobalState("files");
  const selectedFiles = files
    .filter((file: any) => file.selected)
    .map((file: any) => file.id);
  const [messages, setMessages] = useGlobalState("chatMessages");
  const [message, setMessage] = useGlobalState("chatMessage");
  const [responseLoading, setResponseLoading] = React.useState(false);
  let new_message_code = "";
  const [newMessage, setNewMessage] = React.useState<
    { code: string; output: string; id: string } | undefined
  >(undefined);
  const [lastTaskId, setLastTaskId] = React.useState("");

  const [clickingVote, setClickingVote] = React.useState("");
  const [showFiles, setShowFiles] = React.useState(true);
  const [currentMessageId, setCurrentMessageId] = React.useState("");
  const [abortedMessages, setAbortedMessages] = React.useState<string[]>([]);

  const [answerMode, setAnswerMode] = React.useState(
    localStorage.getItem("answerMode")
      ? localStorage.getItem("answerMode")
      : "better"
  );

  React.useEffect(() => {
    var objDiv = document.getElementById("chat");
    if (objDiv?.scrollBy) {
      objDiv.scrollTo(0, objDiv.scrollHeight);
    }
  }, [messages, responseLoading]);

  React.useEffect(() => {
    if (newMessage) {
      const isIDTaken = messages.find(
        (msg) => msg.id === newMessage.id && msg.role === "assistant"
      );

      if (isIDTaken) {
        console.log("ID already taken", newMessage.id);
        return;
      }

      setMessages([
        ...messages,
        {
          role: "assistant",
          code: newMessage.code,
          content: newMessage.output.replace("EULE_IMG:", ""),
          id: newMessage.id,
          image: newMessage.output.startsWith("EULE_IMG:"),
          aborted: abortedMessages.includes(newMessage.id),
        },
      ]);
      setNewMessage(undefined);
    }
  }, [newMessage]);

  const addMessage = (role: string, message: string, id: string) => {
    setMessages([
      ...messages,
      {
        role,
        code: new_message_code,
        content: message,
        id,
      },
    ]);
  };

  const startLoadingResponse = () => {
    setTimeout(() => {
      setResponseLoading(true);
    }, 250);
  };

  const sendMessage = (
    msgId: string,
    code: string,
    message: string,
    delay: number
  ) => {
    setTimeout(() => {
      setResponseLoading(false);
      setNewMessage({ code: code, output: message, id: msgId });
    }, delay);
  };

  const sendImage = (code: string, image: string, id: string) => {
    setResponseLoading(false);
    setNewMessage({ code: code, output: "EULE_IMG:" + image, id: id });
  };

  const [lastQueryTime, setLastQueryTime] = React.useState(
    new Date().getTime() / 1000
  );
  const getResponse = async (message: string, id: string) => {
    setLastQueryTime(new Date().getTime() / 1000);
    startLoadingResponse();
    addMessage("user", message, new Date().getTime().toString());

    if (selectedFiles.length === 0) {
      sendMessage(
        id,
        new_message_code,
        "I'm sorry, please select a file first",
        500
      );
      return;
    }

    const controller = new AbortController();

    setTimeout(() => controller.abort(), 99999999999);

    const request = await fetch("https://api.eule.ai/inference", {
      method: "POST",
      body: JSON.stringify({
        token: token,
        ids: selectedFiles,
        history: [...messages, { role: "user", content: message }],
        mode: answerMode,
        isChat: true,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      signal: controller.signal,
    });

    const result_data = await request.json();

    if (result_data.codes_errors.every((el: any) => el)) {
      if (!inDevMode) {
        sendMessage(
          id,
          "ERROR_CODE_FROM_EULE",
          "I'm sorry, I couldn't answer your question.",
          0
        );
      } else {
        sendMessage(
          id,
          "ERROR_CODE_FROM_EULE",
          "There was an error with the request " + request.status,
          0
        );
      }
      return;
    }

    const result = JSON.parse(result_data.result);

    const codes_tmp: any[] = [];
    const outputs_tmp: any[] = [];

    result.forEach((value: any) => {
      codes_tmp.push(value[0]);
      outputs_tmp.push(value[1]);
    });

    console.log(codes_tmp);

    setLastTaskId(result_data.task_id);

    new_message_code = codes_tmp[0];
    if (result_data.images_ids.length > 0) {
      sendImage(codes_tmp[0], result_data.images_ids[0], id);
    } else {
      sendMessage(id, codes_tmp[0], outputs_tmp[0], 0);
    }
  };

  const vote = (v: string) => {
    console.log(lastTaskId);
    fetch("https://api.eule.ai/chat_vote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        history: messages,
        vote: v,
        task_id: lastTaskId,
      }),
    });
  };

  const sendSuggestion = (suggestion: string) => {
    const id = new Date().getTime().toString();
    setMessages([
      ...messages,
      {
        role: "user",
        code: "SUGGESTION",
        content: suggestion,
        id,
      },
    ]);
    getResponse(suggestion, id);
  };

  return (
    <div className="relative h-full">
      <div className="w-full flex gap-2 items-center justify-center text-7xl font-bold text-brand-blue pb-12">
        <img src="/logo.png" className="h-[4.5rem]" alt="Logo" />
        eule
      </div>
      {inDevMode && (
        <div className="absolute">
          Last Query Finished In: {new Date().getTime() / 1000 - lastQueryTime}
        </div>
      )}
      <div
        id="chat"
        className="h-[30rem] overflow-scroll py-6 bg-white rounded-md shadow-md"
      >
        {messages.length === 0 && (
          <div className="flex flex-col gap-2 items-center text-xl">
            <span className="opacity-75">Here are a few suggestions</span>
            <div className="flex flex-col gap-2 w-full px-[20%] pt-2">
              <Suggestion
                suggestion="How many patients are there?"
                onClick={sendSuggestion}
              />
              <Suggestion
                suggestion="What is the gender ratio?"
                onClick={sendSuggestion}
              />
            </div>
          </div>
        )}

        {messages.map((message) => {
          if (abortedMessages.includes(message.id) && !message.aborted) {
            let tmp_msgs = messages.map((el) => {
              if (el.id !== message.id) return el;
              return { ...el, aborted: true };
            });
            setMessages(tmp_msgs);
          }

          if (message.aborted && message.role === "assistant") {
            return (
              <div className="group flex w-full py-2 items-center">
                <div className="mx-4 bg-red-500 px-4 py-2 w-fit move-in-text rounded-3xl text-white shadow-md max-w-[80%] break-words">
                  Aborted message
                </div>
              </div>
            );
          }

          if (message.role === "assistant") {
            return (
              <div className="flex w-full justify-start items-center group">
                <div
                  className={classNames(
                    "mx-4 bg-gray-500 px-4 py-2 w-fit max-w-[80%] rounded-3xl text-white shadow-md",
                    {
                      "shadow-xl px-0 py-0": message.image,
                    }
                  )}
                >
                  {!message.image && message.content}
                  {message.image && (
                    <img
                      src={`https://api.eule.ai/image/${message.content}.png`}
                      alt="Plot"
                      className="max-h-full max-w-full rounded-3xl"
                    />
                  )}
                </div>
                <FontAwesomeIcon
                  icon={faMicroscope}
                  className="h-5 w-5 cursor-pointer opacity-0 group-hover:opacity-100"
                  onClick={() => {
                    showDeep(
                      messages[messages.indexOf(message) - 1].content,
                      [message.code],
                      message.image ? [] : [message.content],
                      message.image
                        ? [`${message.content.replace("EULE_IMG:", "")}.png`]
                        : []
                    );
                  }}
                />
              </div>
            );
          }
          return (
            <div className="group flex w-full py-2 justify-end items-center">
              <div className="flex gap-2">
                <FontAwesomeIcon
                  icon={faRefresh}
                  className="h-5 w-5 cursor-pointer opacity-0 group-hover:opacity-100"
                  onClick={() => {
                    if (
                      messages.length !== 0 &&
                      messages[messages.length - 1].role !== "assistant"
                    )
                      return;

                    const newId = new Date().getTime().toString();
                    setCurrentMessageId(newId);
                    getResponse(message.content, newId);
                  }}
                />
              </div>
              <div className="mx-4 bg-blue-500 px-4 py-2 w-fit move-in-text rounded-3xl text-white shadow-md max-w-[80%] break-words">
                {message.content}
              </div>
            </div>
          );
        })}
        {responseLoading && (
          <div className="flex  w-full justify-start">
            <div className="ml-4 mt-1 bg-gray-500 px-4 py-2 w-fit rounded-3xl text-white move-in-text">
              <ReactLoading
                type="bubbles"
                color="white"
                width={24}
                height={24}
              />
            </div>

            <div
              className="ml-2 mt-1 bg-red-500 px-4 py-2 w-fit rounded-3xl text-white move-in-text cursor-pointer"
              onClick={() => {
                setAbortedMessages([...abortedMessages, currentMessageId]);
                setMessages([
                  ...messages,
                  {
                    role: "assistant",
                    code: "Aborted message",
                    content: "Aborted message",
                    id: currentMessageId,
                    aborted: true,
                  },
                ]);
                setResponseLoading(false);
              }}
            >
              Abort
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col mt-4 w-full bg-white rounded-md overflow-y-hidden select-none">
        <div className="flex justify-end w-full px-4 pt-4 h-fit items-center bg-white z-[10]">
          {!showFiles && (
            <div className="max-h-12 flex gap-2 w-full">
              <Action
                onClick={() => {
                  setMessages([]);
                }}
              >
                <FontAwesomeIcon icon={faBroom} className="h-4 w-4" />
                Clear
              </Action>

              <Action
                onClick={() => {
                  vote("upvote");
                }}
              >
                <img
                  src="/images/like.png"
                  className="w-6 h-6 invert"
                  alt="upvote"
                />
              </Action>

              <Action
                onClick={() => {
                  vote("downvote");
                }}
              >
                <img
                  src="/images/dislike.png"
                  className="w-6 h-6 invert"
                  alt="downvote"
                />
              </Action>

              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  color="primary"
                  value={answerMode}
                  exclusive
                  onChange={(e, newValue) => {
                    localStorage.setItem("answerMode", newValue);
                    setAnswerMode(newValue);
                  }}
                  aria-label="Platform"
                  className="h-10"
                >
                  <ToggleButton value="faster">Faster</ToggleButton>
                  <ToggleButton value="better" className="lowercase">
                    Better
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </div>
          )}

          {showFiles && (
            <div className="flex flex-col gap-2 overflow-y-scroll h-12 w-full">
              <div className="flex gap-2 overflow-y-scroll h-12">
                {files.map((file: any) => {
                  return (
                    <div
                      className={classNames(
                        "flex gap-2 w-fit px-4 py-2 h-fit rounded-full shadow-md cursor-pointer duration-200",
                        {
                          "bg-brand-blue text-white": file.selected,
                          "bg-brand-gray bg-opacity-10": !file.selected,
                        }
                      )}
                      onClick={() => {
                        const files_tmp = [...files];
                        files_tmp.forEach((file_tmp: any) => {
                          if (file_tmp.id === file.id) {
                            file_tmp.selected = !file_tmp.selected;
                          } else {
                            file_tmp.selected = false;
                          }
                        });
                        setFiles(files_tmp);
                      }}
                    >
                      {file.title}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <FontAwesomeIcon
            onClick={() => {
              setShowFiles(!showFiles);
            }}
            icon={faChevronDown}
            className={classNames(
              "h-5 w-5 text-brand-blue cursor-pointer duration-200",
              {
                "transform rotate-180": showFiles,
              }
            )}
          />
        </div>

        <div className="flex gap-2 w-full bg-white rounded-md shadow-md move-up-animation">
          <Input
            placeholder="Type your message here..."
            className="w-[95%] h-[4.5rem] pl-4 text-xl outline-none rounded-md border-none"
            value={message}
            onChange={(val) => {
              setMessage(val);
            }}
            onEnter={() => {
              if (message === "") return;
              if (
                messages.length !== 0 &&
                messages[messages.length - 1].role !== "assistant"
              )
                return;

              setMessage("");
              const newId = new Date().getTime().toString();
              setCurrentMessageId(newId);
              getResponse(message, newId);
            }}
          />
          <div className="flex items-center justify-center grow w-16">
            <FontAwesomeIcon
              icon={faPaperPlane}
              className="h-4 w-4 text-brand-blue cursor-pointer hover:opacity-80"
              onClick={() => {
                if (message === "") return;
                if (
                  messages.length !== 0 &&
                  messages[messages.length - 1].role !== "assistant"
                )
                  return;

                setMessage("");
                const newId = new Date().getTime().toString();
                setCurrentMessageId(newId);
                getResponse(message, newId);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
