import React from "react";
import { Button } from "./Button";

interface Props {
  taskId: string;
  onFinish: (data: any) => void;
  onAbort: () => void;
  onError: (err: string) => void;
}

export const GeneratingScreen: React.FC<Props> = ({
  taskId,
  onFinish,
  onAbort,
  onError,
}) => {
  const [loadingTime, setLoadingTime] = React.useState(0);

  React.useEffect(() => {
    const loadingTimer = setInterval(() => {
      setLoadingTime((loadingTime) => loadingTime + 1);
    }, 1000);

    (async () => {
      const request = await fetch(
        "https://api.eule.ai/result/" +
          taskId +
          "?token=" +
          localStorage.getItem("token"),
        {
          method: "GET",
        }
      );

      const data = await request.json();

      console.log("Data:", data);

      if (data.error && data.error !== "") {
        console.log("Error Occured:", data.error);
        onError(data.error);
        clearInterval(loadingTimer);
      } else if (request.status !== 200) {
        onError("Unknown error occured");
        clearInterval(loadingTimer);
      } else {
        clearInterval(loadingTimer);
        onFinish(data);
      }
    })();
  }, []);

  return (
    <div className="absolute left-0 top-0 flex flex-col  items-center justify-center w-full h-full">
      <div className="text-4xl font-bold text-brand-blue">Please wait</div>
      <div>
        {loadingTime} second{loadingTime !== 1 ? "s" : ""} elapsed
      </div>
      <Button
        text="Abort"
        className="text-bold mt-4"
        color="blue"
        onClick={onAbort}
      />
    </div>
  );
};
