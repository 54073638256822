import React from "react";
import { Input } from "./Input";

interface Props {
  file: any;
}

export const FileConfig: React.FC<Props> = ({ file }) => {
  const [title, setTitle] = React.useState(file.title || "");
  const [description, setDescription] = React.useState(file.description || "");

  return (
    <div className="flex flex-col gap-2 p-2 border-[1px] border-brand-blue rounded-lg w-full h-fit">
      <div className="text-center">{file.name}</div>
      <Input
        value={title}
        placeholder="Create a title for this file"
        onChange={(val) => {
          setTitle(val);
          file.title = val;
        }}
      />
      <Input
        value={description}
        placeholder="Describe your data"
        onChange={(val) => {
          setDescription(val);
          file.description = val;
        }}
      />
    </div>
  );
};
