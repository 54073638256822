import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

export const Action = ({ children, onClick, className }: Props) => {
  return (
    <div
      className={classNames(
        className,
        "flex gap-2 w-fit px-4 py-2 h-fit rounded-full shadow-md cursor-pointer duration-200 bg-brand-blue text-white items-center hover:bg-opacity-90"
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
