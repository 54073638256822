import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
  suggestion: string;
  onClick: (suggestion: string) => void;
}

export const Suggestion: React.FC<Props> = ({ suggestion, onClick }) => {
  return (
    <div
      className="flex gap-2 items-center justify-center text-base w-full hover:opacity-80 duration-200 cursor-pointer"
      onClick={() => {
        onClick(suggestion);
      }}
    >
      <FontAwesomeIcon
        icon={faPaperPlane}
        className="h-4 w-4 text-brand-blue"
      />
      {suggestion}
    </div>
  );
};
