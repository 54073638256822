import React from "react";
import { Button } from "./Button";

interface Props {
  error: string;
  query_fixes?: any;
  files?: any;
  onExit: () => void;
  onRedo?: (ids: string[], newQuery?: string) => void;
}

export const ErrorPage: React.FC<Props> = ({
  error,
  query_fixes,
  files,
  onExit,
  onRedo,
}) => {
  console.log(error);

  return (
    <div className="absolute left-0 top-0 flex flex-col  items-center justify-center w-full h-full">
      <div className="text-4xl font-bold text-red-500">
        Something went wrong...
      </div>
      {/* <div className="mt-4">{error}</div> */}
      <Button
        text="Back"
        className="text-bold mt-4"
        color="blue"
        onClick={onExit}
      />

      {query_fixes && query_fixes.length > 0 && (
        <div className="flex flex-col w-full items-center justify-center text-center mt-8">
          <div className="text-2xl">Did you mean?</div>
          <div className="flex flex-wrap gap-2 mt-2 justify-center">
            {query_fixes.map((fixed_query: string) => (
              <div
                onClick={() => {
                  onRedo?.(
                    files.map((file: any) => file.id),
                    fixed_query
                  );
                }}
                className="flex gap-2 border-brand-blue border-[1px] p-2 rounded-xl cursor-pointer hover:bg-brand-blue hover:bg-opacity-20 duration-200"
              >
                {fixed_query}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
