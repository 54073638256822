import React from "react";
import { useNavigate } from "react-router-dom";

export const Logo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      className="fixed flex items-center top-4 left-8 text-5xl font-bold text-brand-blue cursor-pointer"
      onClick={() => {
        navigate("/");
      }}
    >
      <img src="/logo.png" className="w-12 h-12" alt="Logo" />
      eule
    </div>
  );
};
