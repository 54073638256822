import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCheck, faTrash, faWrench } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface Props {
  fileName: string;
  fileTitle: string;
  fileDescription: string;
  selected?: boolean;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const FileCard: React.FC<Props> = ({
  fileName,
  fileTitle,
  fileDescription,
  selected,
  onClick,
  onEdit,
  onDelete,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      className="select-none relative aspect-square bg-[#fafafa] blue-gradient rounded-xl p-[1px] shadow-xl hover:shadow-2xl hover:opacity-90 duration-200 cursor-pointer"
    >
      <div
        className="relative flex flex-col items-center justify-center text-center bg-white w-full h-full rounded-xl text-brand-gray font-bold text-lg"
        onClick={onClick}
      >
        {fileTitle}

        {selected && (
          <FontAwesomeIcon
            icon={faCheck}
            color="white"
            className="absolute bottom-1 left-1 aspect-square p-2 bg-brand-blue rounded-full"
          />
        )}
      </div>
      {/* <div
        className={classNames(
          "absolute bottom-2 right-2 flex gap-1 duration-200",
          {
            "opacity-0": !hovered,
          }
        )}
      >
        <div
          className="w-6 h-6 flex items-center justify-center bg-brand-blue rounded-md hover:opacity-90 duration-200"
          onClick={onEdit}
        >
          <FontAwesomeIcon icon={faWrench} color="white" className="w-4 h-4" />
        </div>
        <div
          className="w-6 h-6 flex items-center justify-center bg-brand-blue rounded-md hover:opacity-90 duration-200"
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTrash} color="white" className="w-4 h-4" />
        </div>
      </div> */}
    </div>
  );
};
